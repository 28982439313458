/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.flex-contacts-list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media only screen and (max-width: 400px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    justify-items: center;
    margin: 0 !important;
  }
}
.t-a-c {
  text-align: center;
}
.home{
  margin-top: 5px;
  margin-right: 5px;
}
.float-left {
  float: left;
}
.test1{
  border: 1px solid red;
}
.test2{
  border: 1px solid green;
}
.test3{
  border: 1px solid yellow;
}
.test4{
  border: 1px solid blue;
}
.test5{
  border: 1px solid purple;
}
.test6{
  border: 1px solid pink;
}
.test7{
  border: 1px solid saddlebrown;
}
.max-h-screen-20{
  max-height: calc(100vh - 8rem);
}
.w-screen-20{
  max-width: calc(100vw - 50px);
}
/*********** Scroll Bar ****************/
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
/* ****************************************** */
.w-260{
  width: 65rem;
}
.translate-left-50{
  transform: translateX(50%);
}
/* Snackbar */
simple-snack-bar {
  justify-content: center !important;
}

.mat-snack-bar-custom-alert-success {
  border: 2px solid green !important;
  background-color: white !important;
  color: green !important;
  mat-icon{
    color: green !important;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mat-snack-bar-custom-alert-error {
  border: 2px solid red !important;
  background-color: white !important;
  color: red !important;
  mat-icon{
    color: red !important;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mat-snack-bar-custom-alert-warning {
  border: 2px solid #DF8C32FF !important;
  background-color: white !important;
  color: #DF8C32FF !important;
  mat-icon{
    color: #DF8C32FF !important;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mat-snack-bar-custom-alert-info {
  border: 2px solid #DBEDF2FF !important;
  background-color: #55667CFF !important;
  color: #DBEDF2FF !important;
  mat-icon{
    color: #DBEDF2FF !important;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.w-260{
  width: 65rem;
}
.translate-left-50{
  transform: translateX(50%);
}
/* Snackbar */
simple-snack-bar {
  justify-content: center !important;
}

.mat-snack-bar-custom-alert-success {
  border: 2px solid green !important;
  background-color: white !important;
  color: green !important;
  mat-icon{
    color: green !important;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mat-snack-bar-custom-alert-error {
  border: 2px solid red !important;
  background-color: white !important;
  color: red !important;
  mat-icon{
    color: red !important;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mat-snack-bar-custom-alert-warning {
  border: 2px solid #DF8C32FF !important;
  background-color: white !important;
  color: #DF8C32FF !important;
  mat-icon{
    color: #DF8C32FF !important;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mat-snack-bar-custom-alert-info {
  border: 2px solid #DBEDF2FF !important;
  background-color: #55667CFF !important;
  color: #DBEDF2FF !important;
  mat-icon{
    color: #DBEDF2FF !important;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.responsibles {
  margin-top: 5px;
}
.fit-content{
  width: fit-content;
}
.avatar{
  z-index: 0;
  position: relative;
  top: 0;
  left: 0;
}
.changeAvatar{
  z-index: 1;
  cursor: pointer;
  right: -5%;
  bottom: -5%;
  mat-icon{
    transform: translateY(+10%);
  }
}
#img1:hover + #img2{
  opacity: 30%;
}
#img1:hover + #img3{
  opacity: 30%;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}
.mat-paginator-container {
  padding: 0 5px !important;
  @screen xs {
    padding: 8px 16px !important;
  }
}
.avatar {
  width: 11rem;
  height: 11rem;
  min-width: 11rem;
  min-height: 11rem;
}
